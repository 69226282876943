const style = {
  preTitle: {
    width: 4,
    height: 16,
    borderRadius: 2.5,
  },
  upDownWrap: {
    borderRadius: 40,
  },
  downFont: {
    color: '#ff1a11',
  },
  upFont: {
    color: '#28ca00',
  },
};
export default style;
